<template>
    <div class="login-container">
        <ResetPwd v-model:showResetPwd="showResetPwd" @successs="successs" />
        <div class="login-bg"></div>
        <div class="wrapper">
            <span class="system-name">SERMATEC-储能产品数字化管理系统</span>
            <a-form
                :model="formState"
                name="basic"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 15 }"
                autocomplete="off"
                @finish="goLogin"
            >
                <a-form-item
                    label="用户名"
                    name="username"
                    :rules="[{ required: true, message: '请输入用户名' }]"
                >
                    <a-input v-model:value="formState.username" :maxlength="20" showCount />
                </a-form-item>

                <a-form-item
                    label="密码"
                    name="password"
                    :rules="[{ required: true, message: '请输入密码' }]"
                >
                    <a-input-password
                        v-model:value="formState.password"
                        :maxlength="20"
                        showCount
                    />
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 7, span: 15 }">
                    <a-button block type="primary" html-type="submit" :loading="loading">
                        登录
                    </a-button>
                    <a-button class="reset-btn" block @click="resetFormState">重置</a-button>
                </a-form-item>
            </a-form>
            <span class="version">
                {{ versionInfo?.projectVersion }}
                {{ versionInfo?.profilesName }}
            </span>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { apiLogin, apiIndexData, apiGetVersion } from "@/api";
import { flatMenuPermisssons } from "@/utils/common";
import setAsyncRoutes from "@/router/async";
import ResetPwd from "@/components/ResetPwd";
import { Modal } from "ant-design-vue";
export default defineComponent({
    components: {
        ResetPwd,
    },
    setup() {
        const { commit, getters } = useStore();
        const { push, replace } = useRouter();
        const state = reactive({
            loading: false,
            showResetPwd: false,
            versionInfo: "",
            formState: {
                username: "",
                password: "",
            },
        });

        const getUserPermisson = async () => {
            let res = await apiIndexData();
            if (res.status === "SUCCESS") {
                const {
                    userInfo,
                    menus: menusPermCodes,
                    menuCode2OperatePerms: btnPermCodes,
                } = res?.data;
                commit("user/setUserInfo", userInfo);
                const permObj = flatMenuPermisssons(menusPermCodes);
                commit("app/setMenuPermisson", permObj);
                setAsyncRoutes(permObj);
                const menus = getters["app/sider"]?.menus;
                commit("app/setBtnPerms", btnPermCodes);
                if (menus && menus?.length) {
                    // let path = menus[0][0]?.children[0]?.path;
                    // commit("app/setSider", {
                    //     groupCode: menus[0][0]?.code,
                    //     firstPath: path,
                    //     firstGroupCode: menus[0][0]?.code,
                    //     currentPath: path,
                    // });
                    let path = "/index-workbench";
                    commit("app/setSider", {
                        groupCode: "",
                        firstPath: path,
                        firstGroupCode: "",
                        currentPath: path,
                    });
                    replace(path);
                } else {
                    replace("/403");
                }
            } else {
                state.loading = false;
            }
        };

        const goLogin = async () => {
            state.loading = true;
            let { username, password } = state.formState;
            state.formState.username = username.trim();
            state.formState.password = password.trim();
            let res = await apiLogin(state.formState);
            if (res.status === "SUCCESS") {
                commit("user/setToken", res.data);
                getUserPermisson();
            } else if (res.status == "NEED_UPDATE_PASSWORD") {
                state.showResetPwd = state.formState;
                state.loading = false;
            } else {
                state.loading = false;
            }
        };

        const successs = newPassword => {
            Modal.confirm({
                title: "提示",
                content: "您的密码已修改成功，是否直接登录系统？",
                okText: "登录",
                onCancel: () => {
                    state.formState.password = "";
                },
                onOk: () => {
                    state.formState.password = newPassword;
                    goLogin();
                },
            });
        };

        const resetFormState = () => {
            state.formState.username = "";
            state.formState.password = "";
        };

        const getVersion = async () => {
            let res = await apiGetVersion();
            if (res.status === "SUCCESS") {
                state.versionInfo = res?.data;
            }
        };

        getVersion();

        return {
            ...toRefs(state),
            goLogin,
            resetFormState,
            successs,
        };
    },
});
</script>

<style lang="less" scoped>
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .login-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url("../../assets/login-bg.jpg") no-repeat;
        background-size: 100% 100%;
        //filter: blur(5px);
        z-index: -1;
    }
    .wrapper {
        background: #fff;
        background: rgba(255, 255, 255, 0.4);
        width: 650px;
        height: 450px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        border-radius: 4px;
        .system-name {
            margin-bottom: 40px;
            position: relative;
            text-align: center;
            font-size: 22px;
            color: @primary-color;
            font-weight: bold;
        }
        .ant-form {
            width: 550px;
            .reset-btn {
                margin-top: 8px;
            }
        }
        .version {
            text-align: center;
        }
    }
}
</style>
